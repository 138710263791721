code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media (max-width: 786px) {
  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:last-child {
    display: none;
  }

  .ant-picker-panel-container,
  .ant-picker-footer {
    width: 280px !important;
  }

  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
  }
}

@font-face {
  font-family: 'Gil Sans Light';
  src: url('/public/fonts/gill-sans-w01-light.woff2') format('woff2');
}

@font-face {
  font-family: 'Gil Sans Medium';
  src: url('/public/fonts/gill-sans-w01-medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Gil Sans Bold';
  src: url('/public/fonts/gill-sans-w01-bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto Condensed Regular';
  src: url('/public/fonts/RobotoCondensed/Roboto-Condensed-Regular.woff2') format('woff2');
}

/* .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  animation: none;
  flex-wrap: nowrap;
  direction: ltr;
  @media (max-width: 768px) {
    display: inline-grid;
  }
} */

/* .ant-picker-dropdown {
  @media (max-width: 768px) {
    inset: 51.9333px auto auto 0px !important;
  }
}
.ant-picker-dropdown .ant-picker-panel-container {
  @media (max-width: 768px) {
    max-width: 100vw !important;
    overflow: auto !important;
    inset: 51.9333px auto auto 0px !important;
  }
} */
